<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h1>{{ $t('routes.agedDebtorsReport') }}</h1>
          <p v-if="supplier" class="fw-bold">{{ supplier.name }}</p>
        </div>

        <div v-if="!loading" class="d-flex align-items-center gap-4">
          <el-select :value="supplierId" size="small" placement="end" filterable @change="handleSupplierChange">
            <el-option
              v-for="supplierOption in suppliers"
              :key="supplierOption.id"
              :label="supplierOption.name"
              :value="supplierOption.id"
            />
          </el-select>
          <el-dropdown class="d-flex justify-content-center" trigger="click" placement="bottom" @command="handleAction">
            <Button type="secondary" class="p-1">
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :disabled="!isAdmin" :command="ACTIONS.BALANCE_UPDATE">
                <div class="d-flex align-items-center gap-2 text-typography-primary">
                  <SettingsIcon />
                  <p>{{ $t('payment.paymentTable.singleRowActions.balanceUpdate') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </template>
    <template v-if="!loading" #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
    </template>
    <template v-if="!loading">
      <ClarityAgedDebtorsReport
        v-if="activeTab === 0"
        :supplier-id="supplierId"
        :business-id="currentTenant.id"
        :header="tenantPerspectiveTabTitle"
      />
      <SupplierPerspective
        v-if="activeTab === 1"
        :supplier="supplier"
        :document-type="DOCUMENT_TYPES.AGED_DEBTORS_REPORT"
        :documents="documents"
        :documents-loading="documentsLoading"
      />
    </template>
    <div v-else v-loading="loading" style="height: 50vh" />
    <BalancePaymentModal
      v-if="openPaymentModalSupplier"
      :supplier="supplier"
      :business-id="currentTenant.id"
      @close="openPaymentModalSupplier = null"
    />
  </PageLayout>
</template>

<script>
import { computed, ref } from 'vue';

import { PageLayout, Tabs, Button } from '@/modules/core';
import { KebabIcon, SettingsIcon } from '@/assets/icons';
import { useSuppliersNew } from '@/modules/suppliers';
import { useTenancy, useUser } from '@/modules/auth';
import { DOCUMENT_TYPES } from '@/modules/document/types';
import { useLast12Documents } from '@/modules/document/compositions';

import { SupplierPerspective, ClarityAgedDebtorsReport, BalancePaymentModal } from './components';

const ACTIONS = {
  BALANCE_UPDATE: 'balanceUpdate',
};

export default {
  components: {
    PageLayout,
    Tabs,
    Button,
    KebabIcon,
    SettingsIcon,
    ClarityAgedDebtorsReport,
    SupplierPerspective,
    BalancePaymentModal,
  },
  props: {
    supplierId: { type: String, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { suppliers, loading: suppliersLoading } = useSuppliersNew();
    const { isAdmin, loading: userLoading } = useUser();
    const { documents, loading: documentsLoading } = useLast12Documents(
      computed(() => ({
        businessId: currentTenant.value.id,
        supplierId: props.supplierId,
        types: [DOCUMENT_TYPES.AGED_DEBTORS_REPORT],
      }))
    );

    return {
      ACTIONS,
      loading: computed(() => !currentTenant.value || suppliersLoading.value || userLoading.value),
      tenantName: computed(() => currentTenant.value?.name),
      currentTenant,
      suppliers,
      activeTab: ref(0),
      openPaymentModalSupplier: ref(false),
      isAdmin,
      DOCUMENT_TYPES,
      documents,
      documentsLoading,
    };
  },
  computed: {
    supplier() {
      return this.suppliers.find((s) => s.id === this.supplierId);
    },
    tenantPerspectiveTabTitle() {
      return this.$t('payment.agedDebtorsReport.tabs.tenantPerspective', { tenant: this.tenantName });
    },
    supplierPerspectiveTabTitle() {
      return this.$t('payment.agedDebtorsReport.tabs.supplierPerspective', { supplier: this.supplier?.name });
    },
    tabs() {
      return [
        {
          text: this.tenantPerspectiveTabTitle,
        },
        {
          text: this.supplierPerspectiveTabTitle,
        },
      ];
    },
  },
  methods: {
    handleAction(command) {
      if (ACTIONS.BALANCE_UPDATE === command) this.openPaymentModalSupplier = true;
    },
    handleSupplierChange(supplierId) {
      if (!supplierId) this.$router.replace({ name: 'agedDebtorsReport' });
      else this.$router.replace({ name: 'agedDebtorsReport', params: { supplierId } });
    },
  },
};
</script>
