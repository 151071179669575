<template>
  <PageLayout>
    <template #header>
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ $t('routes.payments') }}</h1>
        <el-select v-if="suppliers.length" v-model="supplierPickedId" size="mini" placement="end" filterable clearable>
          <el-option
            v-for="supplier in suppliersFilter"
            :key="supplier.id"
            :label="supplier.name"
            :value="supplier.id"
          />
        </el-select>
      </div>
      <p class="fw-bold">{{ pickedSupplier }}</p>
    </template>
    <template #dashboard>
      <div class="d-flex gap-4">
        <MonthlyPaymentOverview
          class="w-100"
          :loading="dashboardsLoading"
          :reconciliations="reconciliations"
          :payment-terms="paymentTerms"
          :payments-in-progress="paymentsInProgress"
          :customer="customer"
          :supplier="currentSupplier"
        />
        <AnnualPaymentOverview
          class="w-50"
          :loading="dashboardsLoading"
          :reconciliations="reconciliations"
          :payment-terms="paymentTerms"
          :payments-in-progress="paymentsInProgress"
          :customer="customer"
          :supplier="currentSupplier"
        />
      </div>
    </template>
    <template #tabs>
      <Tabs :tabs="tabs" :active-tab.sync="activeTab" />
      <Tabs v-if="activeTab > 0" :tabs="innerTabs" :active-tab.sync="activeInnerTab" />
    </template>
    <PaymentTable
      v-if="activeTab === 0"
      ref="paymentTable"
      :loading="loading"
      :customer="customer"
      :payment-imbalances="totalPaymentImbalances"
      :supplier-id="supplierPickedId"
      :is-admin="isAdmin"
      :to-close-modals="toCloseModals"
      :picked-reconciliation-id="pickedReconciliationId"
      @chat-open="handleChatOpen"
      @close-reconciliation="handleCloseReconciliation"
      @row-click="handlePaymentTableRowClick"
      @update="paymentImbalancesRefetch"
      @view-change="paginatedPaymentImbalances = $event"
      @reconciliation-modal-close="handleReconciliationModalClose"
      @create-approval="createApproval"
      @delete-approval="deleteApproval"
    />
    <PaymentsInProgress
      v-if="activeTab === 1"
      :suppliers="suppliers"
      :supplier-id="supplierId"
      :active-tab="activeInnerTab"
      :current-supplier="supplierPickedId"
      :tasks-count.sync="paymentsInProgressTasksCount"
      @refetch-payments="refetchPaymentsInProgress"
    />
    <PastPayments
      v-if="activeTab === 2 && activeInnerTab === 0"
      :customer="customer"
      :current-supplier="supplierPickedId"
    />
    <UnmatchedPastPayments
      v-if="activeTab === 2 && activeInnerTab === 1"
      :suppliers="suppliers"
      :payments="unbilledPayments"
      :loading="unbilledPaymentsLoading"
    />
    <PaymentDifferences
      v-if="activeTab === 2 && activeInnerTab === 2"
      :suppliers="suppliers"
      :customer="customer"
      :payment-differences="missingCustomerPayments"
      @paymentDifferenceMatchSuccess="refetchPaymentDifferences"
    />
  </PageLayout>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted, provide, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil, groupBy, prop, omit, equals } from 'ramda';
import Big from 'big.js';

import { PageLayout, Tabs } from '@/modules/core';
import { useSuppliersNew } from '@/modules/suppliers';
import { useUser, useTenancy } from '@/modules/auth';
import { useChatModal } from '@/modules/chatModal';
import { useGlobalPermissions } from '@/modules/permissions';

import { options } from '@/locale/dateConfig';
import { PAYMENT_STATUS } from '../types';
import {
  MonthlyPaymentOverview,
  AnnualPaymentOverview,
  PaymentTable,
  PaymentsInProgress,
  PastPayments,
  UnmatchedPastPayments,
  PaymentDifferences,
} from './components';
import {
  usePaymentsBillingsInProgress,
  usePayables,
  useUnbilledPayments,
  usePaymentPatch,
  usePaymentTerms,
  usePaymentsCount,
  usePaymentState,
  PAYMENT_PAGE_CONTEXT,
} from '../compositions';
import { usePaymentDifferences } from './components/pastPayments/compositions/payment';

const mapImbalanceWithBalanceAndDueDateTerm = (reconciliations, payables, suppliers, terms) => {
  const supplierById = suppliers.reduce((suppliersMap, supplier) => {
    suppliersMap[supplier.id] = supplier;
    return suppliersMap;
  }, {});

  const payablesBalanceBySupplier = payables.reduce((payablesMap, { supplierId, balance }) => {
    payablesMap[supplierId] = balance;
    return payablesMap;
  }, {});

  const termsBySupplier = terms.reduce((termsMap, term) => {
    termsMap[term.supplierId] = term;
    return termsMap;
  }, {});

  const newImbalances = reconciliations.map((reconciliation) => {
    const { paymentDueDate, supplierId, billedAmounts, paidAmounts } = reconciliation;
    const paymentTerm = termsBySupplier[supplierId];
    const paymentStatuses = [];
    if (paymentTerm && Number.isInteger(paymentTerm.paymentDueNet)) {
      const currentDate = DateTime.local();
      const dueDate = DateTime.fromISO(paymentDueDate).toUTC();
      if (currentDate > dueDate) paymentStatuses.push(PAYMENT_STATUS.OVERDUE);
      else if (currentDate >= dueDate.minus({ days: 7 })) paymentStatuses.push(PAYMENT_STATUS.APPROACHING_DUE);
      else paymentStatuses.push(PAYMENT_STATUS.UNPAID);
    }

    const paidAmountsByBillingId = paidAmounts.reduce((paidMap, paidAmount) => {
      if (paidMap[paidAmount.billingId]) paidMap[paidAmount.billingId].push(paidAmount);
      else paidMap[paidAmount.billingId] = [paidAmount];
      return paidMap;
    }, {});

    const billingsLeftToBePaid = billedAmounts.reduce((billingsToPay, billedAmount) => {
      const totalPaidAmount =
        paidAmountsByBillingId[billedAmount.billingId]
          ?.reduce((sum, paidAmount) => sum.add(paidAmount.amount), new Big(0))
          .toNumber() ?? 0;

      const leftToBePaid = new Big(billedAmount.amount).abs().minus(new Big(totalPaidAmount).abs()).toNumber(); // could be credit

      if (leftToBePaid > 0)
        billingsToPay.push({
          ...billedAmount,
          balanceDue: new Big(leftToBePaid).times(Math.sign(billedAmount.amount)).toNumber(), // if credit should be negative
        });
      return billingsToPay;
    }, []);

    return {
      ...reconciliation,
      paymentTerm,
      paymentStatuses,
      totalBilledAmount: billedAmounts.reduce((acc, billedAmount) => acc + billedAmount.amount, 0),
      totalPaidAmount: paidAmounts.reduce((acc, paidAmount) => acc + paidAmount.amount, 0),
      payablesBalance: payablesBalanceBySupplier[supplierId],
      supplier: supplierById[supplierId],
      billingsLeftToBePaid,
      overdue: [],
    };
  });

  const imbalancesBySupplier = newImbalances.reduce((imbalancesMap, imbalance) => {
    if (imbalancesMap[imbalance.supplierId]) imbalancesMap[imbalance.supplierId].push(imbalance);
    else imbalancesMap[imbalance.supplierId] = [imbalance];
    return imbalancesMap;
  }, {});

  Object.entries(imbalancesBySupplier).forEach(([supplierId, relatedImbalances]) => {
    imbalancesBySupplier[supplierId] = relatedImbalances.sort(
      (a, b) => DateTime.fromISO(a.paymentDueDate) - DateTime.fromISO(b.paymentDueDate)
    );
  });

  return newImbalances.map((imbalance) => {
    const { supplierId } = imbalance;
    const indexOfCurrentImbalance = imbalancesBySupplier[supplierId].findIndex((i) => i.id === imbalance.id);
    const overdue = imbalancesBySupplier[supplierId]
      .slice(0, indexOfCurrentImbalance)
      .filter((i) => i.paymentStatuses.includes(PAYMENT_STATUS.OVERDUE))
      .map(({ totalBilledAmount, totalPaidAmount, paymentDueDate }) => ({
        totalBilledAmount,
        paymentDueDate,
        totalUnpaidAmount: totalBilledAmount - totalPaidAmount,
      }));
    return { ...imbalance, overdue };
  });
};

export default {
  name: 'CustomerPayment',
  components: {
    PaymentDifferences,
    PageLayout,
    PastPayments,
    UnmatchedPastPayments,
    MonthlyPaymentOverview,
    AnnualPaymentOverview,
    Tabs,
    PaymentTable,
    PaymentsInProgress,
  },
  props: {
    supplierId: { type: String, default: null },
  },
  setup(props) {
    const { $router, $route, $i18n, $confirm } = getCurrentInstance().proxy;

    const { hasPaymentManageTasks } = useGlobalPermissions();
    const supplierPickedId = computed({
      set: (supplierId) => {
        if (!supplierId) $router.replace({ name: 'payments' });
        else $router.replace({ name: 'payments', params: { businessId: supplierId } });
      },
      get: () => props.supplierId,
    });
    const tenantId = ref($route.params.tenantId);
    const { currentTenant: customer } = useTenancy();
    const { isAdmin } = useUser();

    const {
      reconciliations: nonFilteredReconciliations,
      loading: reconciliationsLoading,
      refetch: refetchReconciliations,
      closeReconciliation,
      createPayment,
      createPaymentLoading,
      createPaymentOnDone,
      reconciliationApprovalCreate,
      reconciliationApprovalDelete,
    } = usePaymentState(computed(() => ({ businessId: tenantId.value, closed: false })));

    const reconciliations = computed(() =>
      nonFilteredReconciliations.value.filter(
        ({ supplierId }) => !supplierPickedId.value || supplierId === supplierPickedId.value
      )
    );

    const options = computed(() => ({ enabled: !!tenantId.value }));

    const {
      payables: nonFilteredPayables,
      loading: payablesLoading,
      refetch: refetchPayables,
    } = usePayables(
      computed(() => reject(isNil)({ businessId: tenantId.value })),
      options
    );

    const payables = computed(() =>
      nonFilteredPayables.value.filter(
        ({ supplierId }) => !supplierPickedId.value || supplierId === supplierPickedId.value
      )
    );

    const paginatedPaymentImbalances = ref([]);
    const {
      payments: allPaymentsInProgress,
      loading: paymentsInProgressLoading,
      refetch: refetchPaymentsInProgress,
    } = usePaymentsBillingsInProgress(
      computed(() => ({ businessId: tenantId.value, completed: false })),
      options
    );

    provide(PAYMENT_PAGE_CONTEXT, { createPayment, createPaymentLoading });

    const transformedAllPaymentsInProgress = computed(() =>
      allPaymentsInProgress.value.map((p) => ({ ...omit(['requestedDate'], p), date: p.date || p.requestedDate }))
    );
    const paymentsInProgress = computed(() =>
      transformedAllPaymentsInProgress.value.filter((p) => !props.supplierId || props.supplierId === p.supplierId)
    );
    const paymentsInProgressCount = ref(0);
    watch(paymentsInProgress, (payments) => (paymentsInProgressCount.value = payments.length), { immediate: true });

    const {
      terms: paymentTerms,
      loading: paymentTermsLoading,
      refetch: refetchTerms,
    } = usePaymentTerms(
      computed(() => ({ businessId: tenantId.value })),
      options
    );

    const { suppliers, loading: suppliersLoading, refetch: refetchSuppliers } = useSuppliersNew();

    const forceLoading = ref(false);
    const paymentImbalancesRefetch = async () => {
      forceLoading.value = true;
      await Promise.all([refetchReconciliations(), refetchPayables(), refetchTerms()]);
      forceLoading.value = false;
    };

    let refetchTimer = null;
    createPaymentOnDone(() => {
      if (refetchTimer) clearTimeout(refetchTimer);
      refetchTimer = setTimeout(() => {
        refetchReconciliations();
        refetchPayables();
        refetchPaymentsInProgress();
      }, 10000);
    });

    const loading = computed(
      () =>
        suppliersLoading.value ||
        payablesLoading.value ||
        reconciliationsLoading.value ||
        paymentTermsLoading.value ||
        forceLoading.value
    );

    const paymentImbalances = computed(() =>
      !loading.value
        ? mapImbalanceWithBalanceAndDueDateTerm(
            reconciliations.value,
            payables.value,
            suppliers.value,
            paymentTerms.value
          )
        : []
    );
    const dateRange = computed(() => {
      if (!paginatedPaymentImbalances.value.length) return null;

      let minDate;
      let maxDate;
      paginatedPaymentImbalances.value.forEach((pi) => {
        [...pi.billedAmounts.map(prop('date')), ...pi.unbilledOrders.map(prop('date'))].forEach((date) => {
          const jsDate = new Date(date);
          if (!minDate) minDate = jsDate;
          if (!maxDate) maxDate = jsDate;
          if (jsDate < minDate) minDate = jsDate;
          if (jsDate > maxDate) maxDate = jsDate;
        });
      });

      const toDate = DateTime.fromJSDate(maxDate);
      const fromDate =
        !supplierPickedId.value && minDate < toDate.minus({ years: 1 }).toJSDate()
          ? toDate.minus({ years: 1 })
          : DateTime.fromJSDate(minDate);
      return {
        fromDate: fromDate.toFormat('yyyy-LL'),
        toDate: toDate.toFormat('yyyy-LL'),
      };
    });

    const reconciliationStatusVariables = ref({});

    watch(
      [dateRange, supplierPickedId, customer],
      (newValues, oldValues) => {
        if (equals(newValues, oldValues)) return;

        const [dateRangeNew, supplierPickedIdNew, customerNew] = newValues;

        reconciliationStatusVariables.value = reject(isNil)({
          ...dateRangeNew,
          supplierId: supplierPickedIdNew,
          businessId: customerNew.id,
        });
      },
      { immediate: true }
    );

    const {
      paymentDifferences,
      loading: paymentDifferencesLoading,
      refetch: refetchPaymentDifferences,
    } = usePaymentDifferences(computed(() => ({ customerId: tenantId.value })));

    const missingCustomerPayments = computed(() =>
      paymentDifferences.value
        .filter(({ customerPayment }) => !customerPayment)
        .filter(
          ({ supplierPayment }) => !supplierPickedId.value || supplierPayment.supplierId === supplierPickedId.value
        )
    );

    const { closeChatModal, openChatModal, isChatOpen } = useChatModal();

    watch(supplierPickedId, () => closeChatModal(), { immediate: true });

    const {
      unbilledPayments: nonFilteredUnbilledPayments,
      loading: unbilledPaymentsLoading,
      refetch: unbilledPaymentsRefetch,
    } = useUnbilledPayments(computed(() => ({ businessId: tenantId.value })));

    const unbilledPayments = computed(() =>
      nonFilteredUnbilledPayments.value.filter(
        ({ supplierId }) => !supplierPickedId.value || supplierId === supplierPickedId.value
      )
    );

    const { onDone: onDonePaymentPatch } = usePaymentPatch();

    onDonePaymentPatch(unbilledPaymentsRefetch);

    const { totalCount: pastPaymentsCount } = usePaymentsCount(
      computed(() => reject(isNil, { completed: true, supplierId: supplierPickedId.value, businessId: tenantId.value }))
    );

    watch(
      [reconciliations, payables, paymentDifferences, unbilledPayments],
      async ([newReconciliations, newPayables, newPaymentDifferences, newUnbilledPayments]) => {
        if (suppliers.value?.length) {
          const suppliersById = groupBy(prop('id'))(suppliers.value);
          const newSupplierInData = [...newReconciliations, ...newPayables, ...newUnbilledPayments].some(
            ({ supplierId }) => !suppliersById[supplierId]
          );
          const newSupplierInPaymentDifferences = newPaymentDifferences
            .filter(({ supplierPayment }) => supplierPayment)
            .map(({ supplierPayment }) => supplierPayment)
            .some(({ supplierId }) => !suppliersById[supplierId]);

          if (newSupplierInData || newSupplierInPaymentDifferences) await refetchSuppliers();
        }
      },
      { immediate: true }
    );

    const toCloseModals = ref(false);

    let hourlyRefetchTimeout = null;

    const refetch = async () => {
      toCloseModals.value = true;
      forceLoading.value = true;
      await Promise.all([
        unbilledPaymentsRefetch(),
        refetchPaymentDifferences(),
        refetchPaymentsInProgress(),
        refetchPayables(),
        refetchReconciliations(),
      ]);
      forceLoading.value = false;
      hourlyRefetchTimeout = setHourRefreshTimeout();
    };

    const setHourRefreshTimeout = () =>
      setTimeout(() => {
        $confirm($i18n.t('payment.confirm.text'), $i18n.t('payment.confirm.title'), {
          type: 'warning',
          showClose: false,
          confirmButtonText: $i18n.t('payment.confirm.refresh'),
          cancelButtonText: $i18n.t('payment.confirm.notNow'),
          cancelButtonClass: 'el-button--secondary',
        })
          .then(refetch)
          .catch(() => (hourlyRefetchTimeout = setHourRefreshTimeout()));
      }, 3600 * 1000);

    onMounted(() => (hourlyRefetchTimeout = setHourRefreshTimeout()));
    onUnmounted(() => clearTimeout(hourlyRefetchTimeout));

    return {
      hasPaymentManageTasks,
      reconciliations,
      paymentTerms,
      closeReconciliation,
      reconciliationApprovalCreate,
      reconciliationApprovalDelete,
      paymentImbalancesRefetch,
      customer,
      suppliers,
      supplierPickedId,
      activeTab: ref(0),
      activeInnerTab: ref(0),
      paymentsInProgressCount,
      paymentsInProgressTasksCount: ref(0),
      totalPaymentImbalances: computed(() =>
        supplierPickedId.value
          ? paymentImbalances.value.filter((i) => i.supplierId === supplierPickedId.value)
          : paymentImbalances.value
      ),
      pastPaymentsCount,
      unmatchedPastPaymentsCount: computed(() => unbilledPayments.value.length),
      loading,
      dashboardsLoading: computed(
        () =>
          reconciliationsLoading.value ||
          paymentTermsLoading.value ||
          paymentsInProgressLoading.value ||
          forceLoading.value
      ),
      closeChatModal,
      openChatModal,
      isChatOpen,
      isAdmin,
      paginatedPaymentImbalances,
      unbilledPayments,
      unbilledPaymentsLoading,
      missingCustomerPayments,
      paymentDifferencesLoading,
      refetchPaymentDifferences,
      paymentsInProgress,
      refetchPaymentsInProgress,
      toCloseModals,
      pickedReconciliationId: ref(null),
    };
  },
  computed: {
    suppliersFilter() {
      const suppliersFiltered = [...this.suppliers];
      return [
        { name: this.$t('payment.allSuppliersFilter'), id: null },
        ...suppliersFiltered.sort((supplierA, supplierB) => supplierA.name.localeCompare(supplierB.name)),
      ];
    },
    pickedSupplier() {
      return this.suppliersFilter.find((s) => s.id === this.supplierPickedId)?.name;
    },
    tabs() {
      return [
        {
          text: this.$t('payment.paymentTable.paymentsForApproval'),
        },
        {
          text: this.$t('payment.paymentsInProgressTab.title'),
        },
        {
          text: this.$t('payment.paymentTable.executedPayments'),
        },
      ];
    },
    innerTabs() {
      switch (this.activeTab) {
        case 1:
          return [
            {
              text: this.$t('payment.paymentsInProgressTab.tabs.paymentStatus'),
              badgeValue: this.paymentsInProgressCount,
            },
            this.hasPaymentManageTasks && {
              text: this.$t('payment.paymentsInProgressTab.tabs.filesToUpload'),
              badgeValue: this.paymentsInProgressTasksCount,
            },
          ];
        case 2:
          return [
            {
              text: this.$t('payment.pastPayments.tabs.matchedPayments'),
              badgeValue: this.pastPaymentsCount,
            },
            {
              text: this.$t('payment.pastPayments.tabs.paymentsToBeMatched'),
              badgeValue: this.unmatchedPastPaymentsCount,
            },
            {
              text: this.$t('payment.pastPayments.tabs.paymentDifferences'),
              badgeValue: this.missingCustomerPayments.length,
            },
          ];

        default:
          return [];
      }
    },
    currentSupplier() {
      return this.suppliersFilter.find((supplier) => supplier.id === this.supplierPickedId);
    },
  },
  watch: {
    activeTab() {
      this.activeInnerTab = 0;
    },
  },
  methods: {
    async handleCloseReconciliation(reconciliationId) {
      try {
        await this.closeReconciliation({ id: reconciliationId });
        this.$message.success(this.$t('payment.paymentTable.messages.reconciliationClosedSuccessfully'));
      } catch (error) {
        console.error(error);
        this.$message.error(this.$t('payment.paymentTable.messages.reconciliationClosingFailed'));
      }
    },
    handleChatOpen({ supplier, customer, reconciliationId, reconciliationClosed, reconciliationPeriod, isDaily }) {
      const formattedPeriod = isDaily
        ? new Date(reconciliationPeriod).toLocaleDateString(this.$i18n.locale, options.twoDigits)
        : DateTime.fromISO(reconciliationPeriod).toLocaleString({
            month: 'short',
            year: 'numeric',
          });
      const title = `${this.$t('chat.billingManagement')} - ${formattedPeriod}`;
      this.openChatModal({
        supplier,
        title,
        formattedPeriod,
        customer,
        reconciliationId,
        isChannelEnabled: !reconciliationClosed,
      });
    },
    handlePaymentTableRowClick({ id, supplier }) {
      this.pickedReconciliationId = id;
      const rowChatChannelUrl = `reconciliation_${this.customer.id}_${supplier.id}`;
      if (!this.isChatOpen(rowChatChannelUrl)) {
        this.closeChatModal();
      }
    },
    handleReconciliationModalClose() {
      this.pickedReconciliationId = null;
      this.closeChatModal();
    },
    createApproval(id) {
      this.reconciliationApprovalCreate(id);
    },
    deleteApproval(id) {
      this.reconciliationApprovalDelete(id);
    },
  },
};
</script>
